<template>
  <div>
    <b-table
      id="mantenimientosTable"
      ref="refMantenimientosListTable"
      class="position-relative"
      :items="listZoneParentMantenimientos"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      :sort-desc.sync="isSortDirDesc"
      :busy="!listZoneParentMantenimientos"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
            class="align-middle"
            small
          />
          <strong class="ml-1">Cargando datos...</strong>
        </div>
      </template>

      <template #cell(encomienda)="data">
        {{ data.item.encomienda ? data.item.encomienda.nombre : "-" }}
      </template>

      <template #cell(task)="data">
        {{ data.item.task ? data.item.task.nombre : "-" }}
      </template>

      <template #cell(actions)="data">
        <b-row align-h="center">
          <b-button
            :id="`details-${data.item.id}`"
            class="mr-1 btn-icon"
            variant="success"
            @click="loadMantenimientos(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-tooltip
            :target="`details-${data.item.id}`"
            triggers="hover"
            placement="bottom"
            boundary="window"
          >
            <b>Detalles</b>
          </b-tooltip>
        </b-row>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
            {{ dataMeta.of }} entradas</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalMantenimientos"
            :per-page="perPage"
            aria-controls="mantenimientosTable"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <b-modal
      v-model="mantenimientosModal"
      title="Mantenimientos realizados"
      hide-footer
      size="lg"
    >
      <b-row class="mb-2">
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Mostrar</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entradas</label>
        </b-col>
      </b-row>
      <b-table
        id="mantenimientosByLocationTable"
        ref="refMantenimientosByLocationListTable"
        :items="mantenimientosByLocation"
        :fields="tableColumns"
        responsive
      >
        <template #cell(encomienda)="data">
          {{ data.item.encomienda ? data.item.encomienda.nombre : "-" }}
        </template>

        <template #cell(task)="data">
          {{ data.item.task ? data.item.task.nombre : "-" }}
        </template>

        <template #cell(actions)="data">
          <b-row align-h="center">
            <b-button
              :id="`delete-${data.item.id}`"
              class="mr-1 btn-icon"
              variant="danger"
              @click="
                selectedItem = data.item;
                deleteModal = true;
                mantenimientosModal = false;
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-tooltip
              :target="`delete-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Eliminar</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>

      <!-- Pagination -->
      <b-row align-h="end">
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPageByLocation"
            :total-rows="totalMantenimientosByLocation"
            :per-page="perPage"
            aria-controls="mantenimientosByLocationTable"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-modal>

    <ConfirmationModal
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el mantenimiento"
      title="¿Está seguro de que desea eliminar el mantenimiento?"
      @action="deleteMantenimiento(selectedItem.id)"
      @close="
        deleteModal = false;
        mantenimientosModal = true;
      "
    />
  </div>
</template>

<script>
import store from '@/store'
import {
  BTable,
  BRow,
  BButton,
  BSpinner,
  BTooltip,
  BPagination,
  BCol,
  BModal,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import vSelect from 'vue-select'
import useMantenimientosList from './useMantenimientoList'
import mantenimientoStoreModule from './mantenimientoStoreModule'

export default {
  components: {
    BTable,
    BTooltip,
    BSpinner,
    BTable,
    BRow,
    BButton,
    BPagination,
    BCol,
    BModal,
    ConfirmationModal,
    vSelect,
  },
  props: {
    zona: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mantenimientosModal: false,
      deleteModal: false,
      selectedItem: {},
    }
  },
  computed: {
    mantenimientosByLocation() {
      return this.$store.state['app-mantenimiento']?.mantenimientos
    },
  },
  methods: {
    openModalMantenimiento(id) {
      this.$set(this.modalVisibility, id, true)
    },
    async loadMantenimientos(item) {
      await this.fetchMantenimientosByLocation(item.zona.id)

      this.mantenimientosModal = true
    },
    async deleteMantenimiento(id) {
      await this.$store.dispatch('app-mantenimiento/deleteMantenimientos', id)
      this.deleteModal = false
      this.refetchData()
    },
  },
  setup(props) {
    const zonasList = ref([])
    const deleteItem = ref(null)

    const MANTENIMIENTOS_APP_STORE_MODULE_NAME = 'app-mantenimiento'

    if (!store.hasModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MANTENIMIENTOS_APP_STORE_MODULE_NAME,
        mantenimientoStoreModule,
      )
    }

    onUnmounted(() => {
      if (store.hasModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME)) store.unregisterModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchMantenimientos,
      tableColumns,
      perPage,
      currentPage,
      totalMantenimientos,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMantenimientosListTable,
      statusFilter,
      refetchData,
      listZoneParentMantenimientos,
      listMantenimientos,
      zona_id,
      fetchMantenimientosByLocation,
      totalMantenimientosByLocation,
      currentPageByLocation,
    } = useMantenimientosList()

    zona_id.value = props.zona.id

    return {
      listZoneParentMantenimientos,
      zonasList,
      listMantenimientos,
      fetchMantenimientos,
      tableColumns,
      perPage,
      currentPage,
      currentPageByLocation,
      totalMantenimientos,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMantenimientosListTable,
      statusFilter,
      deleteItem,
      refetchData,
      zona_id,
      fetchMantenimientosByLocation,
      totalMantenimientosByLocation,
    }
  },
}
</script>
