import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMantenimientosList() {
  const toast = useToast()

  const refMantenimientosListTable = ref(null)
  const refMantenimientosByLocationListTable = ref(null)

  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'zona.nombre', label: 'Localización', sortable: false },
    { key: 'date_mantenimiento', label: 'Fecha', sortable: false },
    { key: 'user.name', label: 'Realizado por', sortable: false },
    { key: 'encomienda', label: 'Encomienda', sortable: false },
    { key: 'task', label: 'Tarea', sortable: false },
    { key: 'actions', label: 'Acciones', sortable: true },
  ]

  const perPage = ref(25)
  const totalMantenimientos = ref(0)
  const totalMantenimientosByLocation = ref(0)
  const currentPage = ref(1)
  const currentPageByLocation = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const zona_id = ref(null)
  const filterFechaInicio = ref(null)
  const filterFechaFin = ref(null)
  const isFiltering = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refMantenimientosListTable.value ? refMantenimientosListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMantenimientos.value,
    }
  })

  const refetchData = () => {
    refMantenimientosListTable.value.refresh()
  }
  const deleteData = id => {
    store.dispatch('app-mantenimiento/deleteMantenimientos', id)
    refMantenimientosListTable.value.refresh()
  }

  const filterData = () => {
    isFiltering.value = true

    if (refMantenimientosListTable.value) refetchData()
  }

  const clearFilter = () => {
    isFiltering.value = false
    filterFechaInicio.value = null
    filterFechaFin.value = null
    searchQuery.value = ''

    refetchData()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, zona_id, currentPageByLocation], () => {
    refetchData()
  })

  const fetchMantenimientosByLocation = zonaId => {
    store
      .dispatch('app-mantenimiento/fetchMantenimientosByLocation', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
          zona_id: zonaId,
        },
        headers: {
          'X-Sort': `mantenimientos.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPageByLocation.value,
        },
      })
      .then(res => {
        const { total } = res
        totalMantenimientosByLocation.value = total
      })
      .catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching mantenimientos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMantenimientos = (ctx, callback) => {
    const body = isFiltering.value ? {
      search: searchQuery.value,
      paginate: perPage.value,
      fecha_inicio: filterFechaInicio.value,
      fecha_fin: filterFechaFin.value,
    } : {
      search: searchQuery.value,
      paginate: perPage.value,
    }
    store
      .dispatch('app-mantenimiento/fetchMantenimientos', {
        body,
        headers: {
          'X-Sort': `mantenimientos.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalMantenimientos.value = total
        callback(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching mantenimientos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listZoneParentMantenimientos = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-mantenimiento/listZoneParentMantenimientos', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
          zona_id: zona_id.value,
        },
        headers: {
          'X-Sort': `mantenimientos.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalMantenimientos.value = total
        resolve(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching mantenimientos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  const listMantenimientos = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-mantenimiento/listMantenimientos', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `mantenimientos.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalMantenimientos.value = total
        resolve(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching mantenimientos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  return {
    fetchMantenimientos,
    tableColumns,
    perPage,
    currentPage,
    currentPageByLocation,
    totalMantenimientos,
    totalMantenimientosByLocation,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMantenimientosListTable,
    refMantenimientosByLocationListTable,

    refetchData,
    listMantenimientos,
    listZoneParentMantenimientos,
    deleteData,
    statusFilter,
    zona_id,
    fetchMantenimientosByLocation,
    filterFechaFin,
    filterFechaInicio,
    isFiltering,
    clearFilter,
    filterData,
  }
}
