import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    module: 'mantenimiento',
    loading: 'false',
    mantenimientos: [],
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_MANTENIMIENTOS(state, payload) {
      state.mantenimientos = payload
    },
  },
  actions: {
    async deleteMantenimientos({ commit, state }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.deleteMantenimientosById(payload)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addMantenimiento({ commit, state }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.createMantenimiento(payload)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchMantenimientosByLocation({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.fetchMantenimientosByLocation(payload)
        commit('SET_MANTENIMIENTOS', res.data)
        return res
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchMantenimientos({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.getAllMantenimientos(payload)
        return res
      } catch (e) {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async listZoneParentMantenimientos({ state, commit }, payload) {
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        return await controller.listZoneParentMantenimientos(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async listMantenimientos({ state, commit }, payload) {
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        return await controller.listWithoutPagination(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async saveMantenimiento({ commit, state }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.createMantenimiento(payload)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
