<template>
  <div>
    <div
      v-for="zona in zonasList"
      :key="zona.id"
    >
      <b-card
        v-if="zona.isMantenimiento"
        :title="zona.nombre"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entradas</label>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <!-- <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Buscar..." /> -->
                <b-button
                  variant="primary"
                  @click="openModalMantenimiento(zona.id)"
                >
                  <span
                    class="text-nowrap"
                  >Anãdir mantenimiento realizado</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <MantenimientoTable :zona="zona" />
        <Mantenimiento-add-modal
          :key="zona.id"
          :modal-id="zona.id"
          :zona="zona"
          message="Are you sure? This action can not be undone"
          title="Delete item"
          @refetchData="refreshTableData"
          @closeModal="hideModalMantenimiento"
        />
      </b-card>
    </div>
    <b-card title="Mantenimientos">
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Mostrar</label>
        <v-select
          v-model="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entradas</label>
      </b-col>
      <b-row
        align-v="end"
        class="mb-2"
      >
        <!-- Inicio Contrato -->
        <b-col>
          <label class="mt-1">Fecha inicio </label>
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="20"
            />
            <flat-pickr
              v-model="filterFechaInicio"
              class="form-control"
              placeholder="dd-mm-yyyy"
              :config="{
                maxDate: filterFechaFin,
                format: 'd-m-Y',
                altFormat: 'd-m-Y',
                allowInput: true,
                altInput: true,
                locale: Spanish,
              }"
            />
          </div>
        </b-col>

        <!-- Fin contrato -->
        <b-col>
          <label class="mt-1">Fecha fin</label>
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="20"
            />
            <flat-pickr
              v-model="filterFechaFin"
              class="form-control"
              placeholder="dd-mm-yyyy"
              :config="{
                minDate: filterFechaInicio,
                format: 'd-m-Y',
                altFormat: 'd-m-Y',
                allowInput: true,
                altInput: true,
                locale: Spanish,
              }"
            />
          </div>
        </b-col>

        <!-- Filtrar -->
        <b-col>
          <b-button
            variant="outline-primary"
            block
            @click="filterData"
          >
            Filtrar
            <feather-icon icon="FilterIcon" />
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            block
            @click="clearFilter"
          >
            Limpiar
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="refMantenimientosListTable"
        :fields="[
          { key: 'id', label: 'ID', sortable: true },
          { key: 'zona.nombre', label: 'Localización', sortable: false },
          { key: 'zona.parent.nombre', label: 'Núcleo', sortable: false },
          { key: 'date_mantenimiento', label: 'Fecha', sortable: false },
          { key: 'user.name', label: 'Realizado por', sortable: false },
          { key: 'encomienda', label: 'Encomienda', sortable: false },
          { key: 'task', label: 'Tarea', sortable: false },
        ]"
        :items="fetchMantenimientos"
        responsive
      >
        <template #cell(encomienda)="data">
          {{ data.item.encomienda ? data.item.encomienda.nombre : "-" }}
        </template>

        <template #cell(task)="data">
          {{ data.item.task ? data.item.task.nombre : "-" }}
        </template>

        <template #cell(actions)="data">
          <b-row align-h="center">
            <b-button
              :id="`delete-${data.item.id}`"
              class="mr-1 btn-icon"
              variant="danger"
              @click="
                selectedItem = data.item;
                deleteModal = true;
                mantenimientosModal = false;
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-tooltip
              :target="`delete-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Eliminar</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
              {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalMantenimientos"
              :per-page="perPage"
              aria-controls="mantenimientosTable"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import store from '@/store'
import {
  BModal,
  BCard,
  BFormGroup,
  BTable,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BSpinner,
  BTooltip,
  BPagination,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import zonaStoreModule from '@/views/work/zonas/zonaStoreModule'
import useZonasList from '@/views/work/zonas/useZonasList'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es'
import MantenimientoAddModal from './MantenimientoAddModal.vue'
import MantenimientoTable from './MantenimientoTable.vue'
import useMantenimientosList from './useMantenimientoList'
import mantenimientoStoreModule from './mantenimientoStoreModule'

export default {
  components: {
    BTooltip,
    BModal,
    BSpinner,
    BCard,
    BFormGroup,
    BTable,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BPagination,
    vSelect,
    MantenimientoTable,
    flatPickr,
    // modal
    MantenimientoAddModal,
  },

  data() {
    return {
      displayModal: false,
      selectedItem: null,
      mantModal: false,
      modalVisibility: {},
      Spanish,
    }
  },

  methods: {
    openModalMantenimiento(id) {
      this.$set(this.modalVisibility, id, true)
    },
    hideModalMantenimiento(id) {
      this.$set(this.modalVisibility, id, false)
    },
    deleteMantenimiento(id) {
      this.$store
        .dispatch('app-mantenimiento/deleteMantenimientos', id)
        .then(() => {
          this.deleteModal = false
          this.refetchData()
        })
    },
  },

  setup() {
    const zonasList = ref([])
    const deleteItem = ref(null)

    const ZONAS_APP_STORE_MODULE_NAME = 'app-zona'
    const MANTENIMIENTOS_APP_STORE_MODULE_NAME = 'app-mantenimiento'

    if (!store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONAS_APP_STORE_MODULE_NAME, zonaStoreModule)
    }

    if (!store.hasModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MANTENIMIENTOS_APP_STORE_MODULE_NAME,
        mantenimientoStoreModule,
      )
    }

    onUnmounted(() => {
      if (store.hasModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME)) store.unregisterModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME)
      if (store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) store.unregisterModule(ZONAS_APP_STORE_MODULE_NAME)
    })

    const { listZonas } = useZonasList()

    const refreshTableData = () => {
      // force table to refresh data
      zonasList.value = []
      listZonas().then(data => {
        zonasList.value = data.filter(zona => !zona.parent)
      })
    }

    listZonas().then(data => {
      zonasList.value = data.filter(zona => !zona.parent)
    })

    const {
      fetchMantenimientos,
      tableColumns,
      perPage,
      currentPage,
      totalMantenimientos,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMantenimientosListTable,
      statusFilter,
      refetchData,
      filterFechaInicio,
      filterFechaFin,
      filterData,
      clearFilter,
      isFiltering,
    } = useMantenimientosList()

    return {
      refreshTableData,
      zonasList,
      fetchMantenimientos,
      tableColumns,
      perPage,
      currentPage,
      totalMantenimientos,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMantenimientosListTable,
      statusFilter,
      deleteItem,
      refetchData,
      filterFechaInicio,
      filterFechaFin,
      filterData,
      clearFilter,
      isFiltering,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
